/** @format */

import { useMemo } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import RichTextEditorWrapper from "components/wrappers/rteWrapper";

export const useGenerateMultilingualData = () => {
	const { language } = useSelector(state => state.user);
	const { languages = [] } = useSelector(state => state.event);
	const generate = (data = {}, fallback = "") => {
		let str = fallback;
		if (data[language]) str = data[language];
		else if (data[languages[0]]) str = data[languages[0]];
		else {
			let val = Object.values(data).find(value => value);
			if (val) str = val;
		}
		return str;
	};

	const generateHtml = (data = {}, fallback = "", wrapped = false) => {
		let html = fallback;
		if (stripHtmlTags(data[language])) html = data[language];
		else if (stripHtmlTags(data[languages[0]])) html = data[languages[0]];
		else {
			let val = Object.values(data).find(value => stripHtmlTags(value));

			if (val) {
				html = val;
			}
		}
		if (wrapped) {
			return <RichTextEditorWrapper>{html}</RichTextEditorWrapper>;
		} else return parse(html);
	};

	return { generate, generateHtml };
};

const stripHtmlTags = input => {
	if (!input) return false;
	// Remove HTML tags using a regular expression
	const strippedString = input.replace(/<[^>]*>/g, "");

	// Check if there is any content left after removing HTML tags
	const hasContent = strippedString.trim().length > 0;

	return hasContent;
};

export const useCombinePerformers = () => {
	const { performers: performerLists = [] } = useSelector(state => state);
	const { schedulePerformers = [] } = useSelector(state => state);
	const allListPerformers = useMemo(() => {
		if (performerLists.length === 0) return [];
		const arr = performerLists.reduce((performerArray, performerList) => {
			const { performers = [] } = performerList;
			if (performers.length === 0) return performerArray;
			let newArr = performerArray;
			performers.forEach(p => {
				if (!newArr.map(pa => pa.id).includes(p.id)) newArr.push(p);
			});
			return newArr;
		}, []);
		return arr;
	}, [performerLists.length]);
	const allNonDistinctPerformers = [...allListPerformers, ...schedulePerformers];
	const distinctPerformerIDs = new Set(allNonDistinctPerformers.map(p => p.id));

	const allPerformers = [...distinctPerformerIDs].reduce((prev, curr) => {
		const findPerformer = allNonDistinctPerformers.find(p => p.id === curr);
		return [...prev, findPerformer];
	}, []);

	return allPerformers;
};
