/** @format */

import React from "react";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { useHistory, useParams } from "react-router";
import { LvtText } from "components/lvtComponents/components";
import { IconImage } from "components/icons";
import { LvtRow } from "components/lvtComponents/components";
import { LvtBtn } from "components/lvtComponents/components";
import { IconChevronLeft } from "components/icons";
import { Localized } from "@fluent/react";
import { useCombinePerformers, useGenerateMultilingualData } from "hooks/generic";

const Performer = props => {
	const { performer_id } = useParams();
	const history = useHistory();
	const performers = useCombinePerformers();
	const performer = performers.find(p => p.id === parseInt(performer_id));
	const { image = null, name = {}, title = {}, description = {}, url = "" } = performer;

	const { generate, generateHtml } = useGenerateMultilingualData();

	const titleToShow = generate(title);
	const nameToShow = generate(name);
	const descriptionToShow = generateHtml(description, "", true);

	return (
		<NavigationWrapper className="single-performer">
			<LvtRow>
				<LvtBtn set="link" className="back" onClick={() => history.goBack()}>
					<IconChevronLeft className="back-icon" /> <Localized id="back" />
				</LvtBtn>
			</LvtRow>
			{image ? (
				<img className="performer-image single" alt="" src={image} />
			) : (
				<div className="performer-image-placeholder">
					<IconImage size="100" className="icon" />
				</div>
			)}
			<LvtText set="h1" className="name">
				{nameToShow}
			</LvtText>
			<LvtText set="label" className="title">
				{titleToShow}
			</LvtText>

			<div className="description">{descriptionToShow}</div>

			{url && (
				<a href={url} target="_blank" className="url">
					{url}
				</a>
			)}
		</NavigationWrapper>
	);
};

export default Performer;
