/** @format */

import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

function extractFontFamily(cssString) {
	const match = cssString.match(/--font-family:\s*([^;]+);/);
	return match ? match[1].trim() : null;
}

const IframeContent = ({ children }) => {
	const iframeRef = useRef(null);
	const { custom_css } = useSelector(state => state.config);

	const fontFamily = extractFontFamily(custom_css);

	useEffect(() => {
		const adjustIframeHeight = () => {
			if (iframeRef.current) {
				const iframe = iframeRef.current;
				const doc = iframe.contentDocument || iframe.contentWindow.document;

				if (doc && doc.body) {
					iframe.style.height = `${doc.body.scrollHeight}px`; // This does not work correctly if the description is short, needs more work
				}
			}
		};

		if (iframeRef.current) {
			const doc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;

			if (doc) {
				doc.open();
				doc.write(`
					<html>
            <head>
              <style>
                html, body {
                  font-family: ${fontFamily};
                  margin: 0;
									padding: 0;
									border: 0;
									width: 100%;
									min-height: 0;
                }
              </style>
            </head>
          <body>${children}</body>
        </html>
				`);
				doc.close();

				// Adjust height after content is written
				adjustIframeHeight();
			}
		}

		// Adjust height if iframe content changes dynamically
		const resizeObserver = new ResizeObserver(adjustIframeHeight);
		const iframeBody = iframeRef.current?.contentDocument?.body;
		if (iframeBody) resizeObserver.observe(iframeBody);

		return () => {
			if (iframeBody) resizeObserver.unobserve(iframeBody);
		};
	}, [children]);

	return <iframe ref={iframeRef} style={{ width: "100%", border: "none", overflow: "hidden" }} />;
};

const RichTextEditorWrapper = ({ children }) => {
	if (!children) return children;
	return <IframeContent>{children}</IframeContent>;
};

export default RichTextEditorWrapper;
